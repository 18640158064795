<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-4" style="margin-left: 30%;">
				<div class="card">
					<div class="card-header">
						<h6>စာရင်း ကြည့်ရန်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row" style="margin-left: 6%;">
								<div class="col-sm-3">
									<input type="radio" v-model="filter.type" value="type1"
										class="form-check-input" id="exampleCheck1">
									<label class="form-check-label" for="exampleCheck1">ပုံမှန်</label>
								</div>
								<div class="col-sm-3">
									<input type="radio" v-model="filter.type" value="type3"
										class="form-check-input" id="exampleCheck2">
									<label class="form-check-label" for="exampleCheck2">အားလုံး</label>
								</div>
								<div class="col-sm-3">
									<input type="radio" v-model="filter.type" value="type2"
										class="form-check-input" id="exampleCheck2">
									<label class="form-check-label" for="exampleCheck2">အရွေး</label>
								</div>
							</div>
							<div v-if="this.$role == 'admin'" class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select v-if="this.$role == 'admin'" id="inputItemId" class="form-control"
										v-model="filter.shop">
										<option value="">ဆိုင်ခွဲ အားလုံး</option>
										<option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select id="inputItemId" class="form-control" v-model="filter.item">
										<option value="">ပစ္စည်း အမျိုးအစား အားလုံး</option>
										<option v-for="item in itemList" :value="item.id">{{item.name}}</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									 <input onfocus="(this.type='month')" class="form-control" placeholder="ဘောင်ချာ နေ့စွဲ"
                    				v-model="filter.nodate">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select id="inputItemId" class="form-control" v-model="filter.notype">
										<option value="">ဘောင်ချာ အမျိုးအစား ရွေးချယ်မည်</option>
										<option value="No">ဆိုင်ခွဲမရှိ</option>
										<option value="A">A</option>
										<option value="B">B</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="number" class="form-control" placeholder="ဘောင်ချာ အမှတ်မှ့ ရှာရန်"
										v-model="filter.nofrom">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="number" class="form-control" placeholder="ဘောင်ချာ အမှတ်ထိ ရှာရန်"
										v-model="filter.noto">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="text" v-model="filter.no" placeholder="ဘောင်ချာ အမှတ်"
										class="form-control" id="inputNo">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="text" class="form-control" placeholder="ပေါင်နှံသူ အမည်"
										v-model="filter.name">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="text" class="form-control" placeholder="နေရပ်လိပ်စာ"
										v-model="filter.address">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input type="text" class="form-control" placeholder="ချေးငွေ" v-model="filter.loan">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input onfocus="(this.type='date')" class="form-control"
										placeholder="နေ့စွဲမှ့ ရှာရန်" v-model="filter.startdate">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input onfocus="(this.type='date')" class="form-control"
										placeholder="နေ့စွဲထိ ရှာရန်" v-model="filter.enddate">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input onfocus="(this.type='date')" class="form-control"
										placeholder="အပေါင်ပြန်ရွေးသည့် နေ့စွဲမှ ရှာရန်" v-model="filter.paydate">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input onfocus="(this.type='date')" class="form-control"
										placeholder="အပေါင်ပြန်ရွေးသည့် နေ့စွဲထိ ရှာရန်" v-model="filter.paydate_to">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input onfocus="(this.type='date')" class="form-control"
										placeholder="သက်တမ်းကုန်ဆုံးမည့် နေ့စွဲမှ ရှာရန်" v-model="filter.expdate_from">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<input onfocus="(this.type='date')" class="form-control"
										placeholder="သက်တမ်းကုန်ဆုံးမည့် နေ့စွဲထိ ရှာရန်" v-model="filter.expdate_to">
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select class="form-control" v-model="filter.status">
										<option value="">ဘောင်ချာ အခြေအနေ အားလုံး</option>
										<option value="processing">လက်ကျန်</option>
										<option value="completed">ရွေးပြီး</option>
										<option value="loss">အပေါင်ဆုံး</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select class="form-control" v-model="filter.referencestatus">
										<option value="">ဘောင်ချာအဟောင်း</option>
										<option value="yes">ရှိသည်</option>
										<option value="no">မရှိပါ</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<select class="form-control" v-model="filter.otherstatus">
										<option value="">အခြား အခြေအနေ အားလုံး</option>
										<option value="keep">အကြီးသိမ်း</option>
										<option value="loss">လက်မှတ်ပျောက်</option>
									</select>
								</div>
							</div>
							<div class="form-group" style="text-align: center">
								<div class="col-sm-12">
									<button type="submit" class="btn btn-primary" style="width: 100%;">ရှာမည်</button>
								</div>
							</div>
						</form>
						 <b-modal id="bv-modal-excel" ref="my-modal" title="Excel Export" hide-footer>
							<button class="btn btn-primary" style="width: 100%;">
								<export-excel :data="json_data" ref="child" :fields="json_fields" worksheet="My Worksheet"
									name="nyein-excel-export.xls">Excel Download
								</export-excel>
							</button>
						</b-modal>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	import moment from 'moment';
	export default {
		name: 'excelExport',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				shopList: [],
				itemList: [],
				filter: {
					type: "type1",
					page: 1,
					reverse: "desc",
					sort: "created_at",
					liststatus: "all",
					notype: "",
					nofrom: "",
					noto: "",
					no: "",
					nodate: "",
					name: "",
					address: "",
					loan: "",
					shop: "",
					item: "",
					startdate: "",
					enddate: "",
					mortgagedate: "",
					paydate: "",
					paydate_to: "",
					expdate_from: "",
					expdate_to: "",
					status: "",
					referencestatus: "",
					otherstatus: ""
				},
				type1 : {
					'ပေါင်နှံသည့်နေ့စွဲ': 'start_date',
					'ဘောင်ချာ အမှတ်': 'no',
					'ပေါင်နှံသူ အမည်': 'name',
					'ပစ္စည်းအမည်': 'type',
					'ချေးငွေ': 'loan',
					'အခြေအနေ': 'status',
					'အကြီးသိမ်း': 'keep',
					'လက်မှတ်ပျောက်': 'loss',
				},
				type2 : {
					'ပေါင်နှံသည့်နေ့စွဲ': 'start_date',
					'ဘောင်ချာ အမှတ်': 'no',
					'ပေါင်နှံသူ အမည်': 'name',
					'ပစ္စည်းအမည်': 'type',
					'ချေးငွေ': 'loan',
					'အတိုး': 'interest',
					'တိုးရင်းပေါင်း': 'loan_interest',
					'အရွေးနေ့': 'pay_date',
				},
				type3 : {
					'ဘောင်ချာ အမှတ်': 'no',
					'လက်ခံသည့်နေ့': 'start_date',
					'ချေးငွေ': 'loan',
					'ပစ္စည်းအမျိုးအစား': 'item',
					'အမျိုးအမည်': 'type',
					'ပေါင်နှံသူ အမည်': 'name',
					'လိပ်စာ': 'address',
					'အရွေးနေ့': 'pay_date',
					'အပေါင်ကာလ': 'total_day',
					'အတိုး': 'interest',
					'တိုးရင်းပေါင်း': 'loan_interest',
					'ရွေး/မရွေး': 'status',
					'ဆုံး/မဆုံး': 'loss',
					'လက်မှတ်ပျောက်/အကြီးသိမ်း': 'keep',
					'မှတ်ချက်': 'reference_note'
				},
				json_fields: {},
				json_data: [],
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.mortgageRequest.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				mortgageClothFieldsAction: 'mortgageClothFieldsAction',
				mortgageClothExcelListAction: 'mortgageClothExcelListAction',
			}),
			async getFields() {
				let option = {
					fields: {
						type: "field"
					}
				}
				await this.mortgageClothFieldsAction({
					...option
				}).then(res => {
					this.filter.shopId = res.data.data.shop[0].id
					this.shopList = res.data.data.shop
					this.itemList = res.data.data.item
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				delete this.json_fields
				if(this.filter.type == "type1"){
					this.json_fields = Object.assign({}, this.json_fields, this.type1);
				}
				if(this.filter.type == "type2"){
					this.json_fields = Object.assign({}, this.json_fields, this.type2);
				}
				if(this.filter.type == "type3"){
					this.json_fields = Object.assign({}, this.json_fields, this.type3);
				}
				let option = {
					sort: this.filter.sort,
					reverse: this.filter.reverse,
					page: this.filter.page,
					liststatus: this.filter.liststatus,
					notype: this.filter.notype,
					nofrom: this.filter.nofrom,
					noto: this.filter.noto,
					no: this.filter.no,
					name: this.filter.name,
					address: this.filter.address,
					loan: this.filter.loan,
					shop: this.filter.shop,
					item: this.filter.item,
					startdate: this.filter.startdate,
					enddate: this.filter.enddate,
					status: this.filter.status,
					referencestatus: this.filter.referencestatus,
					nodate: this.filter.nodate,
					paydate: this.filter.paydate,
					paydate_to: this.filter.paydate_to,
					expdate_from: this.filter.expdate_from,
					expdate_to: this.filter.expdate_to,
					otherstatus: this.filter.otherstatus

				}
				await this.mortgageClothExcelListAction({
					...option
				}).then(res => {
					if (res.status == "success") {
						console.log("asfsadfa")
						this.json_data = res.data.data
						this.isLoading = false;
						this.$refs['my-modal'].show()
					}
				}).catch(err => this.isLoading = true)
			},

		},
		async mounted() {
			this.filter.month = moment(new Date()).format('YYYY-MM')
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}

	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>